<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            stack-label
            :options="rombel"
            v-model="selRombel"
            outlined
            label="Kelas"
            style="min-width: 250px;"
            @input="getGuruMapel"
          ></q-select>
        </div>
        <q-input outlined label="Cari Mapel" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>No</th>
            <th>Nama Mapel</th>
            <th>Nama Guru</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="!selRombel">
            <td class="text-center" colspan="4">
              Silahkan Pilih Kelas Terlebih Dahulu
            </td>
          </tr>
          <tr v-else-if="guruMapel.length == 0">
            <td class="text-center" colspan="4">
              Tidak ada mata pelajaran untuk tingkat pada kelas terpilih
            </td>
          </tr>
          <tr v-for="(val, i) in filAjar" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama_mapel }}</td>
            <td>
              <q-select
                dense
                outlined
                use-input
                @filter="filterFn"
                label="guru"
                :options="filteredGuru"
                v-model="val.id_guru"
              ></q-select>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="right" v-can="[]">
        <q-btn flat label="reset" @click="getGuruMapel"></q-btn>
        <q-btn
          unelevated
          color="primary"
          label="simpan"
          @click="confirm = true"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="confirm">
      <q-card>
        <q-card-section>
          Apa Anda yakin ingin menyimpan perubahan,
          <b>Anda tidak bisa menghapus guru yang sudah dipilihkan!</b>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat color="primary" label="Tidak" v-close-popup></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="Ya"
            @click="save"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      confirm: false,

      //batas baru
      rombel: [],
      selRombel: null,

      guru: [],
      filteredGuru: [],
      guruMapel: [],
    };
  },
  computed: {
    filAjar() {
      if (this.searchTerm == "") return this.guruMapel;
      else {
        return this.guruMapel.filter((val) => {
          let cNama =
            val.nama_mapel
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    await this.getRombel();
    await this.getGuru();
  },
  methods: {
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.filteredGuru = this.guru;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredGuru = this.guru.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    async save() {
      let data = [];
      for (let item of this.guruMapel) {
        if (item.id_guru.value) {
          data.push({
            id_guru_mapel: item.id_guru_mapel,
            id_guru: item.id_guru.value,
            id_mapel: item.id_mapel,
          });
        }
      }
      await this.$http.post(
        `/pengaturan/gurumapelbaru/add/${this.selRombel.value}`,
        data
      );
      await this.getGuruMapel();
      this.$q.notify({
        message: "Pengaturan Guru Mapel berhasil disimpan",
        color: "positive",
      });
    },
    async getRombel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengaturan/gurumapelbaru/getlistrombel/${localStorage.getItem(
          "jenjang"
        )}`
      );
      this.rombel = resp.data;
      this.$q.loading.hide();
    },
    async getGuru() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengaturan/gurumapelbaru/getguru/${localStorage.getItem("jenjang")}`
      );
      resp.data.unshift({ label: "-", value: null });
      this.guru = resp.data;
      this.$q.loading.hide();
    },
    async getGuruMapel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = null;
      if (localStorage.getItem("jenjang") == "MA") {
        resp = await this.$http.get(
          `/pengaturan/gurumapelbaru/getgurumapel/${this.selRombel.value}/${this.selRombel.tingkat}/${this.selRombel.peminatan}`
        );
      } else {
        resp = await this.$http.get(
          `/pengaturan/gurumapelbaru/getgurumapel/${this.selRombel.value}/${this.selRombel.tingkat}`
        );
      }
      for (let item of resp.data) {
        if (item.id_guru) {
          item.id_guru = this.guru.find((val) => val.value == item.id_guru);
        } else {
          item.id_guru = { label: "-", value: null };
        }
      }
      this.guruMapel = resp.data;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
